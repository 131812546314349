<template>
  <v-overlay :value="show">
    <v-card light>
      <img id="close" src="@/assets/close.png" @click="$emit('close')" />
      <h3 class="mt-5 uppercasePopup">{{ title }}</h3>
      <h4 class="mt-5">
        <span class="uppercasePopup">{{ client.nom }} </span>
        <span class="capitalize"
          >{{ client.prenom }} -
          {{ client.TypeClient ? client.TypeClient.typologie : "" }}</span
        >
      </h4>
      <v-row>
        <v-col cols="6" offset="3">
          <validation-observer ref="form">
            <ValidationProvider :name="'commentaire'" :rules="rules">
              <v-textarea
                outlined
                placeholder="Votre commentaire"
                v-model="com"
                no-resize
                hide-details
              ></v-textarea>
            </ValidationProvider>
          </validation-observer>
        </v-col>
        <v-col cols="6" offset="3">
          <v-btn
            @click="verifyFileds"
            block
            class="round"
            rounded
            color="#AF2C70"
            x-large
            dark
            depressed
          >
            {{ btn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    client: {
      type: Object,
      default: () => {},
    },
    commentaire: {
      type: String,
      default: () => "",
    },
    btn: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      rules: "required",

      errors: [],
      com: this.commentaire,
    };
  },
  methods: {
    verifyFileds() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.$emit("backlist", this.com);
          this.com = "";
        } else {
          //display error
          console.log(valid);
          this.errors = [];
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              this.errors.push(msg);
            });
            console.log(this.errors);
            this.$emit("errorFields", this.errors);
          }
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.v-card {
  width: 58.71vw;
  max-width: 1088px;
  height: 63.22vh;
  max-height: 500px;
  position: relative;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}
h4 {
  font-size: 2.1rem;
  font-weight: bold;
}
.v-btn.round {
  border-radius: 12px;
  font-weight: bold;
  font-size: 1em;
}
.theme--light.v-text-field--outlined >>> fieldset {
  border-color: #e8e8e8;
}
.v-textarea >>> textarea::placeholder {
  font-size: 24px;
  font-style: italic;
  font-weight: thin;
  color: #333;
  opacity: 1;
}
.uppercasePopup {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
</style>
